import React from 'react';
import { Badge } from 'antd';
import * as calledStatusUtils from '../../../../../utils/company/call-status';
import { PurchaseOccasionOptions } from '../../table/components/render-column';

export const FORM_SEARCH = ({ options }) => {
  return [
    {
      name: 'caNumber',
      label: 'รหัสบริษัท',
      type: 'INPUT',
      placeholder: 'กรอกรหัสบริษัท',
      span: 12,
    },
    {
      name: 'name',
      label: 'ชื่อบริษัท',
      type: 'INPUT',
      placeholder: 'กรอกชื่อบริษัท',
      span: 12,
    },
    {
      name: 'stageAuto',
      label: 'Stage Auto',
      placeholder: 'เลือก Stage Auto',
      type: 'DROPDOWN',
      span: 8,
      loading: options?.stageAuto?.isLoading,
      properties: {
        options: options?.stageAuto?.data || [],
        valueKey: 'value',
        labelKey: 'label',
      },
    },
    {
      name: 'keySuccess',
      label: 'Key Success',
      type: 'DROPDOWN',
      placeholder: 'เลือก Key Success',
      span: 8,
      loading: options?.keySuccess?.isLoading,
      properties: {
        options: options?.keySuccess?.data || [],
        valueKey: 'value',
        labelKey: 'label',
      },
    },
    {
      name: 'stageManual',
      label: 'Stage Manual',
      placeholder: 'เลือก Stage Manual',
      type: 'DROPDOWN',
      span: 8,
      loading: options?.stageManual?.isLoading,
      properties: {
        options: options?.stageManual?.data || [],
        valueKey: 'value',
        labelKey: 'label',
      },
    },
    {
      name: 'purchaseOccasion',
      label: 'โอกาสในการซื้อ',
      placeholder: 'เลือก โอกาสในการซื้อ',
      type: 'DROPDOWN',
      span: 12,
      loading: options?.purchaseOccasion?.isLoading,
      properties: {
        options: options?.purchaseOccasion?.data || [],
        valueKey: 'value',
        labelKey: 'label',
        optionRender: (option) => (
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <PurchaseOccasionOptions data={option} />
          </div>
        ),
      },
    },
    {
      name: 'salesName',
      label: 'ผู้ดูแล',
      type: 'DROPDOWN',
      placeholder: 'เลือกผู้ดูแล',
      span: 12,
      loading: options?.admin?.isLoading,
      properties: {
        options: options?.admin?.data || [],
        valueKey: 'value',
        labelKey: 'label',
      },
    },
    {
      name: 'calledStatus',
      label: 'สถานะการติดต่อ',
      type: 'DROPDOWN',
      placeholder: 'เลือกสถานะการติดต่อ',
      span: 12,
      loading: options?.callStatus?.isLoading,
      properties: {
        options: options?.callStatus?.data || [],
        valueKey: 'value',
        labelKey: 'label',
        optionRender: (option) => (
          <Badge color={calledStatusUtils.getColor(option?.value)} text={option.label} />
        ),
      },
    },
    {
      name: 'lastedCall',
      label: 'โทรล่าสุด',
      type: 'DATE_RANGE',
      format: 'YYYY/MM/DD',
      span: 12,
    },
    {
      name: 'followUpDate',
      label: 'Follow up date',
      type: 'DATE_RANGE',
      format: 'YYYY/MM/DD',
      span: 12,
    },
  ];
};
