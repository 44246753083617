import { useState, useEffect } from 'react';
import { useCreateCallTransaction, useEndCall, useStartCall } from './use-call-query';
import { openAlert } from '../../../components/alert/useAlert';
import * as dateUtils from '../../../utils/date-time';
import { useStopwatch } from 'react-timer-hook';

export const CALL_STATUS = {
  PENDING: 'pending',
  IDLE: 'idle',
  CALLING: 'calling',
  WAITING_CONFIRM: 'waiting-confirm',
};

function useCallAction({ contactId, companyId, onCallDetails, onSubmit }) {
  const { totalSeconds, isRunning, start, pause, reset } = useStopwatch();
  const [callState, setCallState] = useState({
    status: CALL_STATUS.PENDING,
    startDetails: null,
    endDetails: null,
  });

  const startCall = useStartCall();
  const endCall = useEndCall();
  const createTransaction = useCreateCallTransaction();

  const onLoading = () => {
    setCallState((prev) => ({
      ...prev,
      status: CALL_STATUS.PENDING,
    }));
  };

  useEffect(() => {
    setCallState({ status: CALL_STATUS.IDLE, startDetails: null, endDetails: null });

    if (onCallDetails?.startTime && onCallDetails?.endTime) {
      setCallState((prev) => ({
        ...prev,
        status: CALL_STATUS.WAITING_CONFIRM,
      }));

      const stopwatchOffset = dateUtils.getStopwatchOffset(
        onCallDetails?.startTime,
        onCallDetails?.endTime,
      );

      reset(stopwatchOffset, false);
    } else if (onCallDetails?.startTime) {
      setCallState((prev) => ({
        ...prev,
        status: CALL_STATUS.CALLING,
      }));

      const stopwatchOffset = dateUtils.getStopwatchOffset(onCallDetails?.startTime);
      reset(stopwatchOffset);
    } else {
      setCallState((prev) => ({
        ...prev,
        status: CALL_STATUS.IDLE,
      }));

      reset(null, false);
    }
  }, []);

  const handleStartCall = async () => {
    try {
      start();
      onLoading();
      const startDate = dateUtils.getCurrentTime();

      const reqBody = {
        companyId: companyId,
        userContactId: contactId,
        startTime: startDate,
      };

      const response = await startCall.mutateAsync(reqBody);

      if (response?.success) {
        setCallState((prev) => ({
          ...prev,
          status: CALL_STATUS.CALLING,
          startDetails: response?.result,
        }));
      }
    } catch (err) {
      setCallState((prev) => ({
        ...prev,
        status: CALL_STATUS.IDLE,
      }));
      reset(null, false);
    }
  };

  const handleEndCall = async () => {
    try {
      onLoading();
      pause();
      const endTimeStamp = dateUtils.getCurrentTime();

      const callId = onCallDetails?.onCallId || callState.startDetails?._id;

      if (!callId) return;

      const params = {
        callId: callId,
        reqBody: {
          endTime: endTimeStamp,
        },
      };

      const response = await endCall.mutateAsync(params);

      if (response?.success) {
        setCallState((prev) => ({
          ...prev,
          status: CALL_STATUS.WAITING_CONFIRM,
          endDetails: response?.result,
        }));
      }
    } catch (error) {
      setCallState((prev) => ({
        ...prev,
        status: CALL_STATUS.IDLE,
      }));
      reset(null, false);
    }
  };

  const handleSubmitTransaction = ({ onCallBack }) => {
    const triggerTransaction = async (formValues) => {
      const reqBody = {
        ...formValues,
        callId: onCallDetails?.onCallId || callState.startDetails?._id,
        contactId: onCallDetails?.customerId || callState.startDetails?.customer_id || contactId,
        followUpDate: dateUtils.formatToDate(formValues?.followUpDate),
      };

      const response = await createTransaction.mutateAsync(reqBody);

      if (response?.success) {
        onCallBack();
      }
    };

    onSubmit({
      onAction: (values) =>
        openAlert({
          model: 'confirm',
          title: 'คุณต้องการบันทึกการโทรใช่หรือไม่ ?',
          okText: 'ยืนยัน',
          cancelText: 'ยกเลิก',
          onOk: () => {
            triggerTransaction(values);
          },
          onCancel: () => {},
        }),
    });
  };

  const callTimerProps = {
    time: totalSeconds,
    isRunning: isRunning,
    status: callState?.status,
    onTriggerStartCall: handleStartCall,
    onTriggerEndCall: handleEndCall,
  };

  return {
    callTimerProps,
    onSubmitTransaction: handleSubmitTransaction,
  };
}

export default useCallAction;
